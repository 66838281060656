// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(req) {
  return Json_Encode$JsonCombinators.object([
              [
                "provider_id",
                ID.toJson(req.providerId)
              ],
              [
                "quantity",
                req.quantity
              ],
              [
                "colocation_space",
                req.colocationSpace
              ],
              [
                "company",
                req.businessName
              ],
              [
                "headquarters_address",
                req.headquartersAddress
              ],
              [
                "name",
                req.contactName
              ],
              [
                "email",
                req.businessEmail
              ],
              [
                "phone",
                req.phone
              ],
              [
                "message",
                req.note
              ]
            ]);
}

var Req = {
  toJson: toJson
};

function exec(body) {
  return Rest.$$fetch("/leads/contact_provider", {
              NAME: "Post",
              VAL: toJson(body)
            }, "Empty", undefined, undefined);
}

function toJson$1(req) {
  return Json_Encode$JsonCombinators.object([
              [
                "provider_id",
                ID.toJson(req.providerId)
              ],
              [
                "quantity",
                req.quantity
              ],
              [
                "region",
                req.region
              ],
              [
                "company",
                req.businessName
              ],
              [
                "headquarters_address",
                req.headquartersAddress
              ],
              [
                "name",
                req.contactName
              ],
              [
                "email",
                req.businessEmail
              ],
              [
                "phone",
                req.phone
              ],
              [
                "message",
                req.note
              ]
            ]);
}

var Req$1 = {
  toJson: toJson$1
};

function exec$1(body) {
  return Rest.$$fetch("/leads/contact_provider", {
              NAME: "Post",
              VAL: toJson$1(body)
            }, "Empty", undefined, undefined);
}

var BareMetal = {
  Req: Req$1,
  exec: exec$1
};

export {
  Req ,
  exec ,
  BareMetal ,
}
/* ID Not a pure module */
