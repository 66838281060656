// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LeadContactProviderRequest from "./requests/LeadContactProviderRequest.res.js";

var contactProviderBareMetal = LeadContactProviderRequest.BareMetal.exec;

var contactProvider = LeadContactProviderRequest.exec;

export {
  contactProvider ,
  contactProviderBareMetal ,
}
/* LeadContactProviderRequest Not a pure module */
